<template>
  <div>
    <div class="main-content">
      <div class="upload text-center" @click="uploadFileInput">
        <v-icon color="white" size="56" class="py-4">mdi-cloud-upload-outline</v-icon>
        <div class="f-s-16 f-w-900 pb-4 text-decoration-underline">Upload files from your device</div>
        <div class="text-grey-200 pb-1">support formats: JPEG, PNG, PDF <br> (Up to 3 files)</div>
        <input type="file" ref="uploadFileInput" accept="image/jpeg, image/png, application/pdf" multiple style="display: none;" @change="handleFileUpload">
      </div>

      <div class="mt-8">
        <v-card v-for="(item, index) in layouts.data.file" :key="index" flat rounded outlined class="pa-2 my-2 d-flex justify-space-between">
          <div class="d-flex">
            <v-icon>mdi-paperclip</v-icon>
            <div class="pl-2">{{ item.name }}</div>
          </div>
          <v-icon color="red" @click="onClickDelete(item.name)" v-if="!(layouts.data.progressControl || layouts.data.uploadComplete)">mdi-delete-forever</v-icon>
          <v-icon color="green" v-if="layouts.data.uploadComplete">mdi-check-circle-outline</v-icon>
          <v-progress-linear
            v-if="layouts.data.progressControl"
            indeterminate
            absolute
            bottom
            color="green"
          ></v-progress-linear>
        </v-card>
      </div>

    </div>
    <div class="footer">
      <wap-btn
        :height="50"
        width="100%"
        @click="onClickUpload"
      >Upload
      </wap-btn>
      <wap-btn
        :height="50"
        theme="simple"
        class="mt-3"
        width="100%"
        @click="onClickCancel"
      >Cancel
      </wap-btn>
    </div>
  </div>
</template>

<script>
import WapBtn from '@/components/base/WapBtn'
import { OnlineApi } from '@/api'
import { Tool } from '@/assets/js/util'
export default {
  name: 'UploadBankStatement',
  components: {
    WapBtn
  },
  data: () => ({
    assists: {
      tool: new Tool()
    },
    layouts: {
      data: {
        file: [],
        progressControl: false,
        uploadComplete: false
      }
    }
  }),
  computed: {
  },
  methods: {
    uploadFileInput () {
      this.$refs.uploadFileInput.click()
    },
    handleFileUpload (event) {
      const selectedFile = event.target.files
      if (this.layouts.data.file.length + 1 > 3) {
        this.assists.tool.setPopupInformation('Select no more than three files.')
        return
      }
      if (this.isValidFileType(selectedFile[0])) {
        for (const file of selectedFile) {
          const fileSize = file.size / (1024 * 1024)
          if (fileSize > 10) {
            this.assists.tool.setPopupInformation(`${file.name} file size exceeds 10MB`)
            continue
          }

          const flag = this.layouts.data.file.some((existedFile) => existedFile.name === file.name)
          if (flag) {
            this.assists.tool.setPopupInformation(`${file.name} has already been selected`)
          } else {
            this.layouts.data.file.push(file)
          }
          this.$refs.uploadFileInput.value = ''
        }
      } else {
        this.assists.tool.setPopupInformation('Please ensure that the uploaded file is in the format of JPEG, PNG, or PDF.')
      }
    },
    isValidFileType (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']
      return allowedTypes.includes(file.type)
    },
    onClickDelete (name) {
      this.layouts.data.file = this.layouts.data.file.filter(i => i.name !== name)
    },
    onClickUpload () {
      if (this.assists.tool.isEmpty(this.layouts.data.file)) {
        this.assists.tool.setPopupInformation('Please select a file.')
        return
      }
      if (this.layouts.data.file.length > 3) {
        this.assists.tool.setPopupInformation('Select no more than three files.')
        return
      }
      this.layouts.data.progressControl = true

      const formData = new FormData()
      formData.append('loanNo', this.$store.getters.getLoan.loanNo)
      this.layouts.data.file.forEach((file) => {
        formData.append('files', file)
      })

      OnlineApi.uploadBankStatement(formData, () => {
        this.layouts.data.progressControl = false
        this.layouts.data.uploadComplete = true
        this.$router.push('/notification/104')
      }, (res) => {
        this.layouts.data.progressControl = false
        this.layouts.data.uploadComplete = false
        this.assists.tool.setPopupInformation(res.message)
      })
    },
    onClickCancel () {
      this.$router.push('/notification/205')
    }
  }
}
</script>

<style lang="sass" scoped>
.upload
  border: 3px rgba(160, 160, 210) dashed
  border-radius: 15px
  background-color: var(--theme_secondary)
  cursor: pointer
</style>
